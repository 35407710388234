export enum ForecastReportType {
  LEASES = 'leases',
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export enum ReportUIConfigActionType {
  TOOLTIP = 'tooltip',
  EXPAND = 'expand',
  MODAL = 'modal',
  LINK = 'link',
}
